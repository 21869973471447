import _ga from "~/shared/_ga";
import _gtag from "~/shared/_enhanced-ecommerce.googletagmanager";
import _uet from "~/shared/_uet";
import _gtag_ga4 from "~/shared/ga4/_ga4.ecommerce";

export default {
  measuringProductDetailsView(group, color, list) {
    if (!group.retail_price_list || !group.retail_price_list[0]) return;
    const price = group.retail_price_list[0].current;

    _ga.measuringProductDetailsView(group);
    _gtag.viewsofProductDetails(
      _gtag.buildProductImpression({
        name: group.name,
        price: price.replace('$', ''),
        color: color || undefined,
        category: group.product_category,
        list: list || undefined
      }),
    );
    _uet.productView(group, price);
    _gtag_ga4.view_item(group, price, color);
  },
  addToCart(skuObject, color, cartData) {
    _ga.addToCart(skuObject, color);
    _gtag.addingProductToShoppingCart(
      _gtag.buildProductImpression({
        name: skuObject.name,
        price: skuObject.retail,
        color: skuObject.color,
        category: skuObject.category
      })
    );
    _uet.addToCart(skuObject);
    _gtag_ga4.add_to_cart(skuObject, window.catalog_cfg, color, cartData);
  },
  /**
   * Tracking even removing from cart
   * @param {Array} skusObject array of products
   */
  removeFromCart(skusObject, index) {
    _gtag_ga4.remove_from_cart(skusObject, index);
  },
  trackVirtualPageViews(newPage) {
    _ga.trackVirtualPageViews(newPage);
  },
  measuringCheckout(step, checkoutOption, items, currency, transactionDetails) {
    _gtag.measuringCheckout(step, checkoutOption);
    _uet.measuringCheckout(step);
    if (step == 1) {
      _gtag_ga4.begin_checkout(items, currency, transactionDetails);
    } else if (step == 2 ) {
      _gtag_ga4.add_payment_info(items, currency, transactionDetails);
    }
  },
  sendCustomEvent() {
    _ga.sendEvent(...arguments);
  },
  sendCustomEventVariable(eventName, eventData) {
    _gtag.sendCustomEventVariable(eventName, eventData);
  },
  addShippingInfo(shippingMethod, currency, items, transactionDetails) {
    _gtag_ga4.add_shipping_info(shippingMethod, currency, items, transactionDetails);
  },
  purchase(items, currency, transactionDetails) {
    _gtag_ga4.purchase(items, currency, transactionDetails);
  },
  viewCart(items) {
    _gtag_ga4.view_cart(items);
  },
  cartPage(items, currency, transactionDetails){
    _gtag_ga4.view_cart_page(items, currency, transactionDetails);
  }
}