import $ from "expose-loader?exposes=$,jQuery!jquery";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";

// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

import "./addons/_polyfill.ie11";
import "./addons/searchBar";
import "./addons/myAccount";
import Cookies from "js-cookie";
import "./addons/perfect-footbed";
import "./addons/side-widget";
import "./addons/mail-popup";
import CartOverlay from "./cart/cart-overlay";
import regionDetection from "./popups/region-detection";
import "./addons/footbed-finder";
import "./addons/footbed-tutorial";

// Lazy load images
import LazyLoad from "vanilla-lazyload";
const LazyLoadInstance = new LazyLoad({ threshold: 0.3 });
window.LazyLoadInstance = LazyLoadInstance;

if (window.location.href.match("/account/")) {
  window.hide_mailing_list_popup = 1;
}

// Variables for mailing-list
window.mailing_list = {
  disable_signup: window.hide_mailing_list_popup || 0,
  subscribe_url:
    window.settings.yoursole_url + "/" + window.settings.region + "/mailing-list/subscribe"
};
import "./popups/mailing-list";

document.addEventListener("DOMContentLoaded", function () {
  // Get the current year an place on footer copy
  var currentYear = new Date().getFullYear();
  $(".current-year").html(currentYear);

  var $closeBtn = $(".closeBtn");
  $closeBtn.onclick = function () {
    $(this).closest(".message").slideUp();
  };

  // Accordion
  var $accordions = $(".accordion-header");
  var toggleButtonContentAccordion = function (element) {
    element.html() === "-" ? element.html("+") : element.html("-");
  };

  // check if accordion state is open and change the button toggle
  $accordions.each(function () {
    var status = $(this).data("state"),
      $thisButtonContent = $(this).find(".close"),
      $accordion = $(this).closest(".accordion");

    if (status === "active") {
      toggleButtonContentAccordion($(this).find(".close"));
      $($accordion).addClass("active");
    }

    $(this).on("click", function () {
      if (status === "active") {
        $($accordion).toggleClass("active");
        toggleButtonContentAccordion($thisButtonContent);
        $(this).data("state", "no-active");
      } else {
        $($accordion).toggleClass("active");
        toggleButtonContentAccordion($thisButtonContent);
        $(this).data("state", "active");
      }
    });
  });

  // Form
  var required = $("label.required");
  $(required).each(function () {
    $(this).append("<span class='required'>*</span>:");
  });

  $.urlParam = function (name) {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results == null) {
      return null;
    } else {
      return results[1] || 0;
    }
  };

  $("#myModal").on("shown.bs.modal", function () {
    $("#myInput").focus();
  });

  $(document).ready(function () {
    const cartOverlay = new CartOverlay(document.getElementById("cart-overlay"), {
      config: {
        currency: window.catalog_cfg.currency,
        currency_symbol: window.catalog_cfg.currency_symbol,
        media_root: window.settings.media_root,
        yoursole_url: window.settings.yoursole_url,
        region: window.settings.region,
        free_shipping_amount: catalog_cfg.FREE_SHIPPING_AMOUNT,
        free_shipping_login: catalog_cfg.FREE_SHIPPING_LOGIN,
        shipping_flat_rate_limit: catalog_cfg.SHIPPING_FLAT_RATE_LIMIT
      }
    });

    cartOverlay.getCart();

    window.cart_overlay = {
      renderCart: cartOverlay.renderCart.bind(cartOverlay),
      showCart: cartOverlay.showCart.bind(cartOverlay),
      hideCart: cartOverlay.hideCart.bind(cartOverlay),
      getCart: cartOverlay.getCart.bind(cartOverlay),
      updateCartBubble: cartOverlay.updateCartBubble.bind(cartOverlay),
      showCartforSecond: cartOverlay.showCartforSecond.bind(cartOverlay),
      applyDiscountCode: cartOverlay.applyDiscountCode.bind(cartOverlay),
      cartOverlayInstance: cartOverlay
    };

    // Ajax region detect
    $.ajax({
      url: window.settings.yoursole_url + "/" + window.settings.region + "/region-detect",
      type: "GET",
      data: { page: window.location.pathname },
      success: function (data) {}
    });

    new Swiper(".swiper-alert", {
      loop: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
        disabledClass: "d-none"
      },
      autoplay: {
        delay: 7000,
        disableOnInteraction: true
      },
      on: {
        update: function (swiper) {
          const slideEnabled = document.querySelectorAll(".alert-swiper .swiper-slide").length > 1;
          swiper.enabled = slideEnabled;
        }
      }
    });

    // Region Detection
    const queryString = window.location.search;
    const urlParam = new URLSearchParams(queryString);
    if (!Cookies.get("ip") || urlParam.get("force-ip")) {
      if (localStorage && localStorage.getItem("location")) localStorage.removeItem("location");
      regionDetection.detect();
    }
    $(".region-switcher").on("click", function () {
      regionDetection.getPopupDisplay();
    });

    $(".guarantee-modal-trigger").on("click", function () {
      $("#guarantee").modal("show");
    });

    if (Cookies.get("intl_shipping") === "on") {
      // Toggle CA and Intl flag visibility
      const regionSwitchersImg = document.querySelectorAll(".region-switcher img");
      if (!regionSwitchersImg) return;
      regionSwitchersImg.forEach(img => {
        img.setAttribute("src", img.src.replace('ca-icon', 'international-icon'));
      });
    }
  });
});
