// Following documentation: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list

// Testing: https://support.google.com/analytics/answer/7201382

const mapItems = (items) => {
  let mappedItems = new Array;
  items.forEach(function(item) {
    mappedItems.push({
      item_id: item.code,
      item_name: item.product_name,
      coupon: item.promo,
      discount: item.discounted ? Number(item.reg_price) - Number(item.mv_price) : 0,
      item_brand: "SOLE",
      item_category: item.category,
      item_variant: item.color ? item.color : "",
      location_id: catalog_cfg.currency == 'CAD'
        ? 'ChIJ1T-EnwNwcVMROrZStrE7bSY'
        : 'ChIJ7THRiJQ9UocRyjFNSKC3U1s',
      price: Number(item.reg_price),
      quantity: Number(item.quantity)
    });
  });
  return mappedItems;
}

export default {
  add_to_cart(item, catalog_cfg, color) {
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: catalog_cfg.currency,
        value: Number(item.mv_price),
        items: [
          {
            item_id: item.code,
            item_name: item.product_name,
            coupon: item.promo,
            discount: item.discounted ? Number(item.reg_price) - Number(item.mv_price) : 0,
            item_brand: "SOLE",
            item_category: item.category,
            item_variant: color ? color.data_color : "",
            location_id: catalog_cfg.currency == 'CAD'
              ? 'ChIJ1T-EnwNwcVMROrZStrE7bSY'
              : 'ChIJ7THRiJQ9UocRyjFNSKC3U1s',
            price: Number(item.reg_price),
            quantity: item.quantity
          }
        ]
      }
    });
  },
  view_item(group, price, color) {
    dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: window.catalog_cfg.currency,
        value: Number(price.replace('$', '')),
        items: [
          {
            item_id: group.groups[0],
            item_name: group.name,
            discount: group.retail_on_sale ? Number(group.retail_price_list[0].regular) - Number(group.retail_price_list[0].current) : 0,
            item_brand: "SOLE",
            item_category: group.product_category,
            item_variant: color ? color.data_color : "",
            location_id: window.catalog_cfg.currency == 'CAD'
              ? 'ChIJ1T-EnwNwcVMROrZStrE7bSY'
              : 'ChIJ7THRiJQ9UocRyjFNSKC3U1s',
            price: Number(group.retail_price_list[0].regular),
            quantity: 1
          }
        ]
      }
    });
  },
  remove_from_cart(item) {
    if (!item || !item.mv_price) return;
    dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: window.catalog_cfg.currency,
        value: Number(item.mv_price),
        items: [
          {
            item_id: item.code,
            item_name: item.product_name,
            coupon: item.promo,
            discount: item.discounted ? Number(item.reg_price) - Number(item.mv_price) : 0,
            item_brand: "SOLE",
            item_category: item.category,
            item_variant: item.color ? item.color : '',
            location_id: window.catalog_cfg.currency == 'CAD'
            ? 'ChIJ1T-EnwNwcVMROrZStrE7bSY'
            : 'ChIJ7THRiJQ9UocRyjFNSKC3U1s',
            price: Number(item.reg_price),
            quantity: 1
          }
        ]
      }
    })
  },
  begin_checkout(items, currency, transactionDetails) {
    dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: currency,
        value: Number(transactionDetails.value.replace('$', '')),
        items: items
      }
    });
  },
  add_shipping_info(shippingMethod, currency, items, transactionDetails) {
    dataLayer.push({
      event: 'add_shipping_info',
      ecommerce: {
        currency: currency,
        value: Number(transactionDetails.value.replace('$', '')),
        shipping_tier: shippingMethod,
        items: items,
        coupon: items[0].coupon
      }
    });
  },
  add_payment_info(items, currency, transactionDetails) {
    dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        currency: currency,
        value: Number(transactionDetails.value.replace('$', '')),
        coupon: items[0].coupon,
        payment_type: "Credit Card",
        items: items
      }
    });
  },
  purchase(items, currency, transactionDetails) {
    dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currency: currency,
        value: Number(transactionDetails.value.replace('$', '')),
        transaction_id: transactionDetails.order_number,
        tax: Number(transactionDetails.tax.replace("$", '')),
        shipping: Number(transactionDetails.shipping.replace("$",'')),
        items: items
      }
    });
  },
  view_cart(items) {
    dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: window.catalog_cfg.currency,
        value: items.reduce((accumulator, item) => accumulator + Number(item.price ? item.price : item.mv_price) * item.quantity, 0),
        items: mapItems(items)
      }
    });
  },
  view_cart_page(items, currency, transactionDetails) {
    dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: currency,
        value: Number(transactionDetails.value.replace('$', '')),
        items: items
      }
    });
  }
}