export default {
  addToCart(skuObject) {
    if (!isUETEnabled()) return;
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'add_to_cart', {
      'product_id': skuObject.sku,
      'product_name': skuObject.name,
      'product_brand': 'SOLE',
      'product_price': skuObject.retail,
      'product_category': skuObject.category
    });
  },
  productView(group, price) {
    if (!isUETEnabled()) return;
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'product_view', {
      'product_name': group.name,
      'product_brand': 'SOLE',
      'product_unit_price': price.replace('$', ''),
      'product_category': group.product_category
    });
  },
  shipping() {
    if (!isUETEnabled()) return;
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'shipping', {});
  },
  billing() {
    if (!isUETEnabled()) return;
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'billing', {});
  },
  purchase() {
    if (!isUETEnabled()) return;
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'purchase', {});
  },
  measuringCheckout(step) {
    if (Number(step) === 1) { this.shipping(); };
    if (Number(step) === 2) { this.billing(); };
    if (Number(step) === 3) { this.purchase(); };
  }
}

function isUETEnabled() {
  return window.uetq;
}