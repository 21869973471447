import axios from "axios";
import axiosRetry from 'axios-retry';

const headers = {};
headers['Content-Type'] = 'application/x-www-form-urlencoded';
headers['X-Requested-With'] = 'XMLHttpRequest';

const axiosInstance = axios.create({
  headers,
  baseURL: `${settings.yoursole_url}/${settings.region}/`
});

axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

export default axiosInstance;