// Ajax region detect
import axios from "axios";
import Cookies from "js-cookie";

class RegionDetectionAPI {
  constructor(config) {
    this.yoursole_url_region = `${config.yoursole_url}/${config.region}`;
    this.data = null;
  }
  getParam(name) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(name);
  }
  get(cb) {
    const self = this;
    axios.get(`${this.yoursole_url_region}/region-detect`, {
      params: {
        page: window.location.pathname,
        'force-ip': self.getParam('force-ip')
      }
    }).then(res => {
      this.data = res;
      if (cb) cb(res);
    }).catch(err => {
      console.error(err);
    })
  }
}

class RegionDetection {
  constructor(config) {
    this.config = config;
    this.api = new RegionDetectionAPI(config);
    this.current_region = config.region;
  }

  getPage() {
    var cur_path = window.location.pathname;
    cur_path = cur_path.replace(/^\/(ca|us)/ig, '').replace(/^\//, '');
    return cur_path;
  }

  getCurrentRegion() {
    return this.data.from.region;
  }

  getCurrentCountry() {
    return this.data.from.country;
  }

  getRedirectLink() {
    return this.data.redirect;
  }

  getRedirectRegion() {
    return this.data.to.region;
  }

  getRedirectCountry() {
    return this.data.to.country;
  }

  detect() {
    Cookies.set("ip", 1, { secure: true, path: '/' });
    let that = this;
    this.api.get(res => {
      if (res.data.location) localStorage.setItem('location', JSON.stringify(res.data.location));
      if (res.data.redirect) {
        that.data = res.data;
        window.hide_mailing_list_popup = 1;
        Cookies.set("overlay_displayed", 1, {
          secure: true,
          expires: 30,
          path: "/"
        });
        this.getPopupDisplay();
      }
    })
  }

  getPopupDisplay() {
    $('body').append(this.render());
    $("#region-detection").modal('show');
  }

  render() {
    const destination = this.getPage() ? `&dest=${ this.getPage() }` : "";
    return `
    <div class="modal fade" id="region-detection" tabindex="-1" role="dialog" aria-labelledby="region-detection" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered contact-page" role="document">
        <div class="modal-content shadow border-0">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="background: #000; opacity: 1; top: -24px; right: -24px;">
              <svg height="30px" width="30px" class="close-x" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 75 75"
                enable-background="new 0 0 75 75" xml:space="preserve">
                <path style="fill: white;"
                  d="M43.2,37.5l18.6-18.6c1.6-1.6,1.6-4.1,0-5.7c-1.6-1.6-4.1-1.6-5.7,0L37.5,31.8L18.9,13.2  c-1.6-1.6-4.1-1.6-5.7,0c-1.6,1.6-1.6,4.1,0,5.7l18.6,18.6L13.2,56.1c-1.6,1.6-1.6,4.1,0,5.7c1.6,1.6,4.1,1.6,5.7,0l18.6-18.6  l18.6,18.6C56.9,62.6,58,63,59,63s2.1-0.4,2.9-1.2c1.6-1.6,1.6-4.1,0-5.7L43.2,37.5z">
                </path>
              </svg>
            </button>
          </div>
          <div class="modal-body text-center d-flex justify-content-center flex-column">
            <div class="mt-3 text-uppercase">
              <p class="h3" style="font-size: 28px; line-height: 36px; color: black;">Select a country:</p>
            </div>
            <a href="${ window.settings.yoursole_url }/${ window.settings.region }/switch-region/ca?intl=off${ destination }">
              <div class="region-flag mt-3">
                <img width="125" height="63" src="${ window.settings.yoursole_url }/media/ysdotcom/images/icons/flags/ca.png" alt="Switch to Canadian Store">
                <p class="h6 text-capitalize mt-0 mb-4">Canada</p>
              </div>
            </a>
            <a href="${ window.settings.yoursole_url }/${ window.settings.region }/switch-region/us?intl=off${ destination }">
              <div class="region-flag mt-4">
                <img width="125" height="63" src="${ window.settings.yoursole_url }/media/ysdotcom/images/icons/flags/us.png" alt="Switch to US Store">
                <p class="h6 text-capitalize mt-0 mb-4">United States</p>
              </div>
            </a>
            <a href="${ window.settings.yoursole_url }/${ window.settings.region }/switch-region/ca?intl=on${ destination }">
              <div class="region-flag mt-3">
                <img width="125" height="63" src="${ window.settings.yoursole_url }/media/ysdotcom/images/icons/flags/international.png" alt="Switch to International Store">
                <p class="h6 text-capitalize mt-2 mb-0">International*</p>
              </div>
            </a>
            <p class="mt-4 mb-0 shipping-option" style="font-size: 10px; line-height: 1.5; font-family: 'GloberRegular' ">*You will be transferred to our Canada store to ship internationally. Some items in your cart may not be available. ${window.catalog_cfg.INTL_SHIPPING_OPTIONS_NOTICE}</p>
          </div>
        </div>
      </div>
    </div>
  `;
  }
}

const regionDetection = new RegionDetection(window.settings);
export default regionDetection;
