import _filterBuilder from "./_filterBuilder";
import Cookies from "js-cookie";

// LOGO importing
import soleLogo from "Images/sole-white.svg";
import shape_img from "YsdotcomImages/tutorial/tutorial-mobile.png";
import moldable_img from "YsdotcomImages/tutorial/heat-wear-moldable.png";
import thickness_img from "YsdotcomImages/tutorial/thickness.png";
import softec_img from "YsdotcomImages/tutorial/softec.png";
import recork_img from "YsdotcomImages/tutorial/recork.png";
import polygiene_img from "YsdotcomImages/tutorial/polygiene.png";



function render() {
  const id = "footbed-tutorial-modal-popup";
  const el = document.querySelector("#" + id);

  const start = {
    _scene: "start",
    _styleClass: {
      content: "FT__blue text-white text-center",
      body: "align-items-center justify-content-center",
    },
    _hideControl: ["skip", "prev"],
    logo: {
      src: soleLogo,
      alt: "Sole.",
      width: 182,
      height: 52,
    },
    logo_title: "FOOTBED TUTORIAL",
    description:"<div>Follow this quick tutorial to learn more about SOLE footbeds</div>",
    buttons_1: [
      {
        label: "Start",
        _styleClass: "mt-5"
      }
    ]
  };
  
  // todo: add content for these footbed points:
  const shape = {
    _scene: "shape",
    _hideControls: ["skip", "close"],
    _progress: 15,
    _styleClass: { body: "justify-content-center align-items-center" },
    title: "Shape",
    logo: {
      src: shape_img,
      alt: "shape",
      width: 400,
      height: 500,
    },
    question: "All SOLE footbeds feature the same Signature Supportive Shape. It offers high, dynamic arch support, a deep heel cup, and a zero drop platform with your heel at the same height as the ball of your foot."
  };

  const moldable = {
    _scene: "moldable",
    _hideControls: ["skip", "close"],
    _progress: 30,
    _styleClass: { body: "justify-content-center align-items-center" },
    title: "Custom Moldable",
    logo: {
      src: moldable_img,
      alt: "moldable",
      width: 182,
      height: 52,
    },
    question: "Our footbeds offer custom support for your unique feet. They can be heat molded in your oven at home (ideal for low arches) or will mold to your feet over a few days of wear. Once molded, they act like a dynamic second skin, distributing pressure evenly, reducing strain and promoting neutral alignment."
  };

  const thickness = {
    _scene: "thickness",
    _hideControls: ["skip", "close"],
    _progress: 45,
    _styleClass: { body: "justify-content-center align-items-center" },
    title: "Thickness",
    logo: {
      src: thickness_img,
      alt: "Thickness",
      width: 182,
      height: 100,
    },
    question: "SOLE footbeds come in three thicknesses. Our Thin footbeds are best used in cleats, skates, dress shoes and anything that fits on the snug side. Our Medium footbeds fit great in ‘regular’ fitting shoes like sneakers and running shoes. Our Thick footbeds fit best in footwear with wiggle room like hiking boots and winter boots."
  };
  const softec = {
    _scene: "softec",
    _hideControls: ["skip", "close"],
    _progress: 60,
    _styleClass: { body: "justify-content-center align-items-center" },
    title: "Softec",
    logo: {
      src: softec_img,
      alt: "softec",
    },
    question: "For shock absorption and comfort, our Medium and Thick footbeds boast SOLE’s proprietary Softec open-cell foam technology. This open-cell cushioning offers longer-lasting comfort than commonly used closed-cell foams."
  };
  const recork = {
    _scene: "recork",
    _hideControls: ["skip", "close"],
    _progress: 75,
    _styleClass: { body: "justify-content-center align-items-center" },
    title: "ReCORK",
    logo: {
      src: recork_img,
      alt: "recork",
      width: 182,
      height: 52,
    },
    question: "Our Performance footbeds are made using natural recycled cork from our own wine cork recycling initiative, ReCORK. Cork is an incredibly sustainable material and fantastic carbon sink. Our Performance footbeds are carbon negative, meaning they absorb more carbon from the atmosphere than they add."
  };
  const polygiene = {
    _scene: "polygiene",
    _hideControls: ["skip", "close"],
    _progress: 90,
    _styleClass: { body: "justify-content-center align-items-center" },
    logo: { src: polygiene_img,
            alt:"Polygiene logo",
            width: 100,
            height: 100 
    },
    question: "Feet that have the most fun, also tend to have the most funk. You don’t need to fear the foot stink though: with the addition of Polygiene® Stays Fresh Technology, your footbeds will stay fresher for longer."
  };

  const end = {
    _scene: "end",
    _hideControls: false,
    _styleClass: {
      body: "align-items-center justify-content-center",
    },
    title_1: "CONGRATULATIONS!",
    question: "You’re now a SOLE expert. Enjoy your footbeds!"
  };

  const footbedtutorial = new _filterBuilder(el, {
    prefix: "FT",
    id,
    effect: "fade-in",
    scenes: [
      start,
      shape,
      moldable,
      thickness,
      softec,
      recork,
      polygiene,
      end
    ]
  });

  return footbedtutorial;
}

function renderIfFilterIsEmpty() {
  const state = getStateFromCookie();
  const id = "footbed-tutorial-modal-popup";
  const el = document.querySelector("#" + id);
  let size_prefix = state.gender ? (state.gender == "mens") ? "M" : "W" : "";

  const footbedtutorial = new _filterBuilder(el, {
    prefix: "FT",
    id,
    effect: "fade-in",
    scenes: [
      empty_filter
    ]
  });

  footbedtutorial.buttons.prev.do = () => {
    removeCookie();
    window.history.back();
  }

  footbedtutorial.showButton("prev");
  return footbedtutorial;
}

document.addEventListener("DOMContentLoaded", function() {
  const footbedtutorial = render();
  window.footbedtutorial = footbedtutorial;
  if (window.location.hash === "#footbed-tutorial") {
    window.hide_mailing_list_popup = 1; // hide mailing list sign up
    footbedtutorial.showModal();
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'footbed-tutorial', 'open', `${window.location.pathname} - open`);
    }
  } else {
    if (getStateFromCookie() && !isProductVisibleAfterFilter()) {
      const footbedtutorial = renderIfFilterIsEmpty();
      footbedtutorial.showModal();
      if (typeof ga !== 'undefined') {
        ga('send', 'event', 'footbed-tutorial', 'open', `${window.location.pathname} - open`);
      }
    };
  };

  $("#footbed-tutorial-modal-popup").addClass( !window.settings && window.sole_config ? "ysdotcom" : "YourSole");

  /* CURRENTLY NO FOOTBED TUTORIAL BUTTON, BUT MAY ADD IN FUTURE 
  $('a.btn[href="#footbed-tutorial"]').on('click', function() {
    footbedtutorial.showModal();
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'footbed-tutorial', 'open', `${window.location.pathname} - open`);
    }
  });
  */

  $('.FT__button--start').on('click', function() {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'footbed-tutorial', 'click', `${window.location.pathname} - start`);
    }
  });

  $('#footbed-tutorial-modal-popup-content-modal').on('hide.bs.modal', function() {
    let currentSlideIndex = window.footbedtutorial.sceneIndex;
    let currentSlideName = window.footbedtutorial.scenes[currentSlideIndex]._scene;
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'footbed-tutorial', 'close', `${window.location.pathname} - close`);
    }
  });
});


function setStateToCookie(state) {
  var inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
  Cookies.set("ft_state", state, {
    secure: true,
    expires: inFiveMinutes
  })
}

function getStateFromCookie() {
  if (Cookies.get("ft_state")) {
    return JSON.parse(Cookies.get("ft_state"));
  }
  return;
}

function removeCookie() {
  Cookies.remove("ft_state");
}

function isProductVisibleAfterFilter() {
  const products = document.querySelectorAll('.product-list > .product-group .product');
  if (products && products.length) {
    const visibleProduct = [...products].filter(product => {
      return product.offsetWidth > 0 || product.offsetHeight > 0;
    })
    return !!visibleProduct.length;
  } else {
    return true; // Quickly disable this feature
  }
}
