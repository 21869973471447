// widget-sidebar
$(window).scroll(function () {
  if ($(window).scrollTop() > 70) {
    $(popButton).addClass('visible')
    window.userScrolled = true
  } else {
    $(popButton).removeClass('visible')
  }
})

// if ( $(".header:matches(/FOOTBEDS/)"):visible) { display widget }

var popButton = $('.widget-sidebar')
var popButtonA = $(popButton).find('ul a').not('.mobile-only')
var $widgetSidebarWrapper = $('.widget-sidebar-wrapper')
var wrapperPanelStatus = false
var closeWidgetSidebarButton = $('.closeWidgetDesktop')

$(closeWidgetSidebarButton).click(function () {
  hideSideWidget();
})

var openPanel = function (status) {
  if (status === true && wrapperPanelStatus === false) {
    $(popButton).addClass('active')
    $($widgetSidebarWrapper).addClass('active')
    wrapperPanelStatus = true
  } else if (status === true && wrapperPanelStatus === true) {
  } else {
    $($widgetSidebarWrapper).removeClass('active')
    wrapperPanelStatus = false
  }
}

$(popButton).find('ul a').not('.mobile-only').click(function (e) {
  var hrefid = $(this).attr('href')
  var getContent = $(hrefid)
  $(popButton).addClass('active')

  if ($(this).hasClass('active')) {
   
  } else {

    $('.widget-sidebar ul a').removeClass('active')
    $('.widget-sidebar-content').removeClass('active')
    $(this).addClass('active')
    $(popButton).addClass('active')
    $(hrefid).addClass('active')
    openPanel(true)

    if (!$(hrefid).hasClass('active')) {
      $(getContent).addClass('active')
      // openPanel(false)
    } else {

    }
  }
})

$(popButton).mouseleave(function () {
  if (window.innerWidth > 768) {
    clearTimerFade()
    clearTimerButtonRemoveActive()
    window.menufade = setTimeout(function () {
      hideSideWidget()
    }, 1)
  
    window.popButtonRemoveActive = setTimeout(function () {
      $(popButton).removeClass('active')
    }, 1)
  };
});

$(popButton).mouseenter(function () {
  if (window.innerWidth > 768) {
    clearTimerFade()
    clearTimerButtonRemoveActive()
  };
});

$(popButtonA).mouseenter(function (event) {
  if (window.innerWidth > 768) {
    var targetElement = this;
    window.menufadein = setTimeout(function () {
      openSideWigetPanel(targetElement)
    }, 1);
  };
});

var ChangePanelContent = function (targetPanel) {
  if (targetPanel === '#insole-sidecontent-2') {
    $('#insole-sidecontent-1').removeClass('active')
    $('#insole-sidecontent-2').addClass('active')
  } else{
    $('#insole-sidecontent-1').addClass('active')
    $('#insole-sidecontent-2').removeClass('active')
  }
}

var openSideWigetPanel = function (element) {
  var href = $(element).attr('class')
  var hrefid = $(element).attr('href')
  var target = $(element).attr('id')
  clearTimerFade()
  clearTimerButtonRemoveActive()
  ChangePanelContent(target)

  if ($(element).hasClass('active')) {

  } else {
    $(popButtonA).removeClass('active')
    $(element).addClass('active')
    openPanel(true)
  }
}

var clearTimerFadeIn = function () {
  window.clearTimeout(window.menufadein)
}

var clearTimerFade = function () {
  window.clearTimeout(window.menufade)
}

var clearTimerButtonRemoveActive = function () {
  window.clearTimeout(window.popButtonRemoveActive)
}

var hideSideWidget = function () {
  openPanel(false)
  wrapperPanelStatus = false
  $(popButton).find('ul a').removeClass('active');
}
