import _createElementFromHTML from "Utilities";
import CartAPI from "./cart-api";

class Product {
  constructor({ product, cart, config, index }) {
    this.index = index;
    this.product = product;
    this.config = config;
    this.cart = cart;
    const page = window.location.pathname.split("/").filter(el => el !== "" && el !== null);
    const ignorePages = ["shipping", "shipping-fixed", "payment", "checkout"];
    this.disableAddRemove = ignorePages.includes(page[page.length - 1]);
  }

  get shopURL() {
    return this.config.yoursole_url + '/' + this.config.region + '/shop/';
  }

  get product_url() {
    return this.shopURL + (this.product.webstore_path ? this.product.webstore_path : 'products')
  }

  setDisableAddRemove(flag) {
    this.disableAddRemove = flag;
  }

  disableAdd() {
    this.productDom.querySelector('.Cart__overlay-qandp-quantity-add').classList.remove('Cart__overlay-qandp-quantity-add-hover-black');
  }

  // Default status is in_stock
  getStockMessage() {
    let status = this.product.shipping_status ? this.product.shipping_status : "in_stock";
    let confirmed_eta;
    if (this.product.confirmed_eta) {
      confirmed_eta = new Date(this.product.confirmed_eta + 'T00:00');
      confirmed_eta = confirmed_eta.toLocaleDateString('default', { month: 'long' }) + " " + confirmed_eta.getDate();
    }

    let messages = {
      out_of_stock: `This item is currently unavailable`,
      low_on_stock: `Only <span class="Cart__overlay-product-stock-message-low-on-stock">${this.product.in_stock_available_qty}</span> left in stock`,
      next_eta: `We expect this item to ship on <span class="Cart__overlay-product-stock-message-next-eta">${confirmed_eta}</span>`,
      in_stock: "",
      final_sale: 'This item is <span class="Cart__overlay-product-stock-message-final-sale">Final Sale</span>'
    }
    if (messages[status]) {
      return {
        status: status,
        message: messages[status]
      };
    } else {
      if (this.product.shipping_status && this.product.shipping_status_message) return {
        status,
        message: this.product.shipping_status_message
      };
      return;
    }
  }

  getSize() {
    if (this.product.category != 'footbeds' || (this.product.map_selected && this.product.map_selected != 'default') || !window.catalog_cfg.size_map) return this.product.size;
    let size_map = window.catalog_cfg.size_map;
    let men_size = "Men's " + this.product.size_number;
    let women_size = "Women's " + size_map["footbeds"]["default"][this.product.size_number]["womens"];
    return men_size + " / " + women_size;
  }

  updateProductData(res) {
    this.product = res.data[0].cart.find(el => el.code === this.product.code);
  }
  updateProduct() {
    if (this.product) {
      this.productDom.replaceWith(this.renderProduct());
    } else {
      this.productDom.remove();
    }
  }
  renderProduct() {
    let productString = `
      <div class="${this.getStockMessage() ? 'Cart__overlay-product-with-stock-message' : 'Cart__overlay-product'} d-flex flex-column align-items-center justify-content-center">
        <div class="Cart__overlay-product-container d-flex justify-content-center align-items-center flex-row">
          <a href="${this.product_url}">
          <div class="col-4 m-0 p-0 Cart__overlay-product-image Cart__overlay-product-image--bgcolor d-flex align-items-center"">
            <img
              class="w-100 align-self-center"
              src="${this.config.media_root}/${this.product.img_path}/small/${this.product.img_filename}.jpg"
            >
          </div>
          </a>
          <div class="col-8 pl-2 pt-2 pb-2 pr-0 m-0 text-left align-self-center">
            <div class="d-flex flex-column">
              <a class="text-decoration-none" href="${this.product_url}">
              <div class="Cart__overlay-product-name Cart__overlay-product-name--font font-weight-bold mt-1">
                ${this.product.product_name}
              </div>
              ${this.product.color ? `
              <div class="Cart__overlay-product-color font-weight-light small mt-1">
                Color: ${this.product.color}
              </div>
              `: ""}
              ${this.product.size ? `
              <div class="Cart__overlay-product-size font-weight-light small mt-1">
                Size: ${this.getSize()}
              </div>` : ""}
              </a>
              <div class="Cart__overlay-product-qandp d-flex flex-row justify-content-start align-items-end mt-1">
                ${!this.disableAddRemove ?
        `<div class="Cart__overlay-product-qandp-quantity font-weight-light small d-flex flex-row align-items-center justify-content-start">
                    <div class="Cart__overlay-product-qandp-quantity-remove ${this.product.quantity == 0 ? '' : `Cart__overlay-product-qandp-quantity-remove-hover-black`} pr-1 pl-2 clickable">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px" height="14px"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 256c0 17.7-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>
                    </div>
                    <div class="Cart__overlay-product-qandp-quantity-counter font-weight-bold pl-3 pr-3 pt-2 pb-2">${this.product.quantity}</div>
                    <div class="Cart__overlay-product-qandp-quantity-add ${this.product.quantity < this.product.available_qty ? `Cart__overlay-product-qandp-quantity-add-hover-black` : ''} pl-1 pr-2 clickable">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px" height="14px"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M240 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H176V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H240V80z"/></svg>
                    </div>
                  </div>`
        :
        `<div class="Cart__overlay-product-size font-weight-light small">
                    Quantity: ${this.product.quantity}
                  </div>`
      }
                <div class="Cart__overlay-product-loading-js justify-content-center align-items-center mb-1" style="display: none">
                  <img src="/media/ysdotcom/images/icons/loading1.svg" width="35" height="35"/>
                </div>
                <div class="Cart__overlay-product-qandp-price Cart__overlay-product-qandp-price--font font-weight-bold ml-auto d-flex flex-column align-items-end">
                  ${this.product.discounted ? `
                  <div class="Cart__overlay-product-qandp-price-regular text-right text-nowrap ${this.product.discounted ? "Cart__overlay-product-qandp-price-regular-discounted d-flex flex-row" : "d-none"}">
                    ${this.config.currency_symbol + Number(this.product.reg_price).toFixed(2)}
                    <div class="font-weight-normal pl-1 d-flex align-items-center small">${this.config.currency}</div>
                  </div>
                  ` : ""}
                  <div class="Cart__overlay-product-qandp-price-current text-right text-nowrap font-weight-bold d-flex flex-row">${this.config.currency_symbol + Number(this.product.mv_price).toFixed(2)}
                    <div class="font-weight-normal pl-1 d-flex align-items-center small">${this.config.currency}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        ${this.getStockMessage() ? `
        <div class="Cart__overlay-product-stock-message">
          ${this.getStockMessage() ? this.getStockMessage().message : ''}
        </div>` : ''
      }
      </div>`;
    let productDom = _createElementFromHTML(productString);
    if (!this.productDom) this.productDom = productDom;

    if (!this.disableAddRemove) {
      const addBtn = productDom.querySelector('.Cart__overlay-product-qandp-quantity-add');
      const removeBtn = productDom.querySelector('.Cart__overlay-product-qandp-quantity-remove');
      const loading = productDom.querySelector('.Cart__overlay-product-loading-js');
      const productAPI = new CartAPI(this.product.code, this.config);
      const that = this;

      addBtn.addEventListener('click', function () {
        if (addBtn.classList.contains('Cart__overlay-product-qandp-quantity-add-hover-black')) {
          loading.style.display = 'flex';
          productAPI.add(1, function (result) {
            that.cart.renderCart(result.data[0]);
            that.updateProductData(result);
            that.updateProduct();
          });
        } else {
          return;
        }
      });

      removeBtn.addEventListener('click', function () {
        loading.style.display = 'flex';
        let removeFromCart;
        if (that.product.quantity == 1) {
          if (window.cartUpdateItem) {
            removeFromCart = function () {
              window.cartUpdateItem({
                code: that.product.code,
                quantity: 0,
                ___productIndex: that.index
              })
            }
          }
        }
        productAPI.remove(1, that.product, function (result) {
          if (removeFromCart) removeFromCart();
          that.cart.renderCart(result.data[0]);
          that.updateProductData(result);
          that.updateProduct();
        });
      });

      // Remove product if quantity is 0
      if (this.product.quantity == 0 && that.product.shipping_status !== 'reserved') {
        productAPI.remove(1);
      }
    }

    this.product.___productIndex = this.index;
    this.getStockMessage();
    if (window.cartUpdateItem) window.cartUpdateItem(this.product);
    return productDom;
  }
}

export default Product;
