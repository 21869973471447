const helpers = {
  buildProductImpression({
    name,
    price,
    brand,
    category,
    color,
    list,
    position
  }) {
    const productObj = {
      'name': name, // Name or ID is required.
      'price': price,
      'brand': brand || 'SOLE',
      'category': category,
      'variant': color || undefined,
      'list': list || undefined,
      'position': position || undefined
    };
    return productObj
  }
};

export default {
  ...helpers,
  productsImpression(impressions) {
    if (!_checkGTagEnabled()) return;
    // Measures product impressions and also tracks a standard
    // pageview for the tag configuration.
    // Product impressions are sent by pushing an impressions object
    // containing one or more impressionFieldObjects.
    dataLayer.push({ 'ecommerce': null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'ecommerce': {
        'currencyCode': window.catalog_cfg.currency, // Local currency is optional.
        'impressions': impressions
      }
    });
  },
  /**
   * Call this function when a user clicks on a product link. This function uses the event
   * callback datalayer variable to handle navigation after the ecommerce data has been sent
   * to Google Analytics.
   * @param {Object} productObj An object representing a product.
   */
  productClick(productObj, list) {
    if (!_checkGTagEnabled()) return;
    dataLayer.push({
      'event': 'productClick',
      'ecommerce': {
        'click': {
          'actionField': { 'list': list || undefined },      // Optional list property.
          'products': [productObj]
        }
      }
    });
  },
  viewsofProductDetails(productObj, list) {
    if (!_checkGTagEnabled()) return;
    // Measure a view of product details. This example assumes the detail view occurs on pageload,
    // and also tracks a standard pageview of the details page.
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'ecommerce': {
        'detail': {
          'actionField': { 'list': list || undefined },    // 'detail' actions have an optional list property.
          'products': [productObj]
        }
      }
    });
  },
  addingProductToShoppingCart(productObj) {
    if (!_checkGTagEnabled()) return;
    // Measure adding a product to a shopping cart by using an 'add' actionFieldObject
    // and a list of productFieldObjects.
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': window.catalog_cfg.currency,
        'add': {                                // 'add' actionFieldObject measures.
          'products': [productObj]
        }
      }
    })
  },
  removingProductFromShoppingCart(productsObj) {
    if (!_checkGTagEnabled()) return;
    // Measure the removal of a product from a shopping cart.
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'event': 'removeFromCart',
      'ecommerce': {
        'remove': {                               // 'remove' actionFieldObject measures.
          'products': productsObj
        }
      }
    });
  },
  measurePromotionImpressions(promoObj) {
    if (!_checkGTagEnabled()) return;
    // An example of measuring promotion views. This example assumes that
    // information about the promotions displayed is available when the page loads.
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'ecommerce': {
        'promoView': {
          'promotions': [
            // {
            //   'id': promoObj.id,  // Name or ID is required.
            //   'name': promoObj.name,
            //   'creative': promoObj.creative,
            //   'position': promoObj.pos
            // }
            promoObj
          ]
        }
      }
    });
  },
  /**
   * Call this function when a user clicks on a promotion. This function uses the eventCallBack
   * datalayer variable to handle navigation after the ecommerce data is sent to Google Analytics.
   *
   * @param {Object} promoObj An object representing an internal site promotion.
   */
  promoClick(promoObj) {
    if (!_checkGTagEnabled()) return;
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'event': 'promotionClick',
      'ecommerce': {
        'promoClick': {
          'promotions': [
            // {
            //   'id': promoObj.id,  // Name or ID is required.
            //   'name': promoObj.name,
            //   'creative': promoObj.creative,
            //   'position': promoObj.pos
            // }
            promoObj
          ]
        }
      }
    });
  },
  /**
   * A function to handle a click leading to a checkout option selection.
   */
  measuringCheckout(step, checkoutOption) {
    if (!_checkGTagEnabled()) return;
    dataLayer.push({ 'ecommerce': null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': { 'step': step, 'option': checkoutOption }
        }
      }
    });
  },
  measuringPurchase(sumarry, products) {
    if (!_checkGTagEnabled()) return;
    // Send transaction data with a pageview if available
    // when the page loads. Otherwise, use an event when the transaction
    // data becomes available.
    dataLayer.push({ 'ecommerce': null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'ecommerce': {
        'purchase': {
          // {
          //   'id': 'T12345',                         // Transaction ID. Required for purchases and refunds.
          //   'affiliation': 'Online Store',
          //   'revenue': '35.43',                     // Total transaction value (incl. tax and shipping)
          //   'tax':'4.90',
          //   'shipping': '5.99',
          //   'coupon': 'SUMMER_SALE'
          // }
          'actionField': sumarry,
          'products': [
            // {                            // List of productFieldObjects.
            //   'name': 'Triblend Android T-Shirt',     // Name or ID is required.
            //   'id': '12345',
            //   'price': '15.25',
            //   'brand': 'Google',
            //   'category': 'Apparel',
            //   'variant': 'Gray',
            //   'quantity': 1,
            //   'coupon': ''                            // Optional fields may be omitted or set to empty string.
            // }
            products
          ]
        }
      }
    });
  },
  sendCustomEventVariable(eventName, eventData) {
    if (!_checkGTagEnabled()) return;
    if (!eventName || typeof eventName === 'object') {
      dataLayer.push(eventName)
    } else {
      let obj = {};
      obj[eventName] = eventData;
      dataLayer.push(obj);
    }
  }
}

function _checkGTagEnabled() {
  if (!window.dataLayer) return false;
  return true;
}