import axios from "axios";
import _analytics from "../../shared/_analytics";

class CartAPI {
  constructor(sku = null, config) {
    this.yoursole_url_region = `${config.yoursole_url}/${config.region}`;
    this.sku = sku;
  }
  setSku(sku) {
    this.sku = sku;
  }
  add(quantity = 1, cb, config) {
    if (!this.sku) console.error("No SKU defined", this);
    const ref = `${this.yoursole_url_region}/cart/add_ajax/${this.sku}/${quantity}`;
    axios.get(ref, config).then(res => {
      if (res.data && res.data[0].cart) {
        const that = this;
        let product = res.data[0].cart.find(el => el.code === that.sku);
        _analytics.addToCart({
          ...product,
          quantity
        });
      }
      if (cb) cb(res);
    }).catch(err => {
      if (cb) cb(null, err);
    })
  }
  remove(quantity = 1, product, cb) {
    if (!this.sku) console.error("No SKU defined", this);
    const ref = `${this.yoursole_url_region}/cart/remove_ajax/${this.sku}/${quantity}`;
    axios.get(ref).then(res => {
      _analytics.removeFromCart({
        ...product,
        quantity
      });
      if (cb) cb(res);
    }).catch(err => {
      if (cb) cb(null, err);
    })
  }
}

export default CartAPI;
