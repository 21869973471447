export default {
  measuringProductDetailsView(group) {
    if (!checkGAEnabled() || !checkGASettings()) return;
    ga('ec:addProduct', {
      'name': group.name,
      'category': group.product_category
    });
    ga('ec:setAction', 'detail');
    ga('send', 'pageview');
    if (!checkFBQEnabled() || !checkFBQSettings()) return;
    fbq('track', 'ViewContent', {
      content_ids: group.slug,
      content_type: 'product_group',
      product_catalog_id: window.settings.pixelCatalogId
    });
  },
  addToCart(skuObject, color) {
    if (!checkGAEnabled()) return;
    ga('ec:addProduct', {
      'id': skuObject.sku,
      'name': skuObject.name,
      'category': skuObject.product_category,
      'variant': color ? color.data_color : "",
      'quantity': 1
    });
    ga('ec:setAction', 'add');
    ga('send', 'event', 'UX', 'click', 'add to cart');
    ga('send', 'pageview');
  },
  removeFromCart(skusObject) {
    if (!checkGAEnabled()) return;
    skusObject.forEach(skuObject => {
      ga('ec:removeProduct', {
        'id': skuObject.sku,
        'name': skuObject.name,
        'category': skuObject.product_category,
        'variant': skuObject.color ? skuObject.color : "",
        'quantity': 1
      });
      ga('ec:setAction', 'remove');
      ga('send', 'event', 'UX', 'click', 'remove from cart');
      ga('send', 'pageview');
    })
  },
  trackVirtualPageViews(newPage) {
    if (!checkGAEnabled()) return;
    ga('set', 'page', newPage);
    ga('send', 'pageview');
  },
  sendEvent(trackingParams) {
    if (!checkGAEnabled()) return;
    if (!arguments) return;
    ga('send', 'event', ...arguments);
  }
}

function checkGAEnabled() {
  return window.ga;
}

function checkGASettings() {
  return window.settings && window.settings.googleAnalyticId
}

function checkFBQEnabled() {
  return window.fbq;
}

function checkFBQSettings() {
  return window.settings && window.settings.pixelCatalogId;
}