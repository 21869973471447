    var gender = 'mens';
    var style = '';
    var met = ''; 

    $(".perfect-footbed-start").click(function() { $("#perfect-footbed").addClass('hidden'); $("#perfect-footbed-second-slide").removeClass('hidden'); $("#perfect-footbed-second-slide").addClass('active')});

    $(".question-one").click(function() { $(".question-one").removeClass('active'); gender = $(this).data("answer"); $(this).addClass('active'); $("#perfect-footbed-second-slide").addClass("hidden");$("#perfect-footbed-third-slide").removeClass("hidden"); });
    $(".question-two").click(function() { $(".question-two").removeClass('active'); style = $(this).data("answer"); $(this).addClass('active');$("#perfect-footbed-second-slide").addClass("hidden"); $("#perfect-footbed-third-slide").addClass("hidden");$("#perfect-footbed-fourth-slide").removeClass("hidden"); });
    $(".question-three").click(function() { $(".question-three").removeClass('active'); met = $(this).data("answer"); $(this).addClass('active'); console.log(gender, style, met) });

    //Target slides for indicator
    $(".second-slide-target").click(function() {$("#perfect-footbed-second-slide").removeClass("hidden"); $("#perfect-footbed-third-slide").addClass("hidden");$("#perfect-footbed-fourth-slide").addClass("hidden")})
    $(".third-slide-target").click(function() {$("#perfect-footbed-second-slide").addClass("hidden"); $("#perfect-footbed-third-slide").removeClass("hidden");$("#perfect-footbed-fourth-slide").addClass("hidden")})

    function showResults() {
      var link = window.page_url
      if ( gender ) { link += gender; } else { link += 'mens'; }
      link += "?";
      if ( style ) { link += style + "&"; }
      if ( met == "NO") { link += "&noPad=1"; }
      link += "&recommend=1";
      $(".show-results").attr("href", link)
    } 

    $("#perfect-footbed-fourth-slide .show-results").click(function() { showResults(); $('perfect-footbed a').removeClass('active')})

    $("#perfect-run-footbed-fourth-slide .show-results").click(function() { showResults(); $('perfect-footbed a').removeClass('active')})

    
    // function showActiveResult() {
    //   var link = "[area secure=1]";
    //   if ( gender ) { link += gender; } else { link += 'mens'; }
    //   link += "/footbeds/active-";
    //   if ( style ) { link += style; }
    //   link += '?utm_source=Personal%20Shopper&utm_medium=Risk%20Free';
    //   $.ajax({
    //     url: link,
    //     success: function() { window.location.href = link; }
    //   });
    // }
    // $("#perfect-active-footbed-second-slide .question-one").click(function() { $("#perfect-active-footbed-second-slide .fancybox-large").click(); })
    // $("#perfect-active-footbed-third-slide .question-two").click(function() { $(".perfect-footbed .next").show(); })
    // $("#perfect-active-footbed-third-slide .show-results").click(function() { showActiveResult(); $('perfect-footbed a').removeClass('active') })

    // [if cgi perfect-footbed]
    // $(document).ready(function() { $(".perfect-footbed-start.fancybox-large").click(); });
    // [/if]

    $(function(){

      if(window.location.hash == "#perfect-footbed")
      $(".perfect-footbed").removeClass("hidden")
    });