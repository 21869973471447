document.addEventListener("DOMContentLoaded", function() {

  $('a.cart-menu').hover(function() {
    if ($('.widget-sidebar').hasClass('active')) {
      $('.widget-sidebar').removeClass('active')
      $('.widget-sidebar-wrapper').removeClass('active')
      $('#insole-sidecontent-2').css('display', 'none');
    }
  });

});