function submitForm(event) {
  var option = "mens";
  document
    .querySelectorAll("#YS__search-box input[type=radio]")
    .forEach(function(el) {
      if (el.checked) option = el.value;
    });
  var form = document.getElementById("YS__search-box");
  form.action =
    form.getAttribute("data-action") +
    option +
    document.getElementById("YS__search-bar").value;
  document.getElementById("YS__search-box").submit();
}

if (document.getElementById("YS__search-button")) {
  document
    .getElementById("YS__search-button")
    .addEventListener("click", e => submitForm(e));
}
